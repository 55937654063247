import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "submitButton"];

  connect() {
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    if (this.inputTarget.value.trim() === "") {
      this.submitButtonTarget.disabled = true;
      this.submitButtonTarget.classList.add("disabled");
    } else {
      this.submitButtonTarget.disabled = false;
      this.submitButtonTarget.classList.remove("disabled");
    }
  }
}
