import { Controller } from "@hotwired/stimulus";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import "dayjs/locale/es";

dayjs.extend(weekday);

const month_names = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const isLeapYear = (year) => {
  return (
    (year % 4 === 0 && year % 100 !== 0 && year % 400 !== 0) ||
    (year % 100 === 0 && year % 400 === 0)
  );
};

const getFebDays = (year) => {
  return isLeapYear(year) ? 29 : 28;
};

export default class extends Controller {
  static targets = ["days", "year", "month"];
  static values = {
    initialDate: String,
    selectableDates: Array,
    markedDates: Array,
    fireOnInit: Boolean,
    partnerId: String,
    url: String,
  };

  initialize() {
    this.date = dayjs(this.initialDateValue);

    console.log(this.date);

    this.month = this.date.month();
    this.year = this.date.year();
    this.generateCalendar();

    if (this.fireOnInitValue) {
      // this is neccesary to handle when the calendar-content-loader-controller is defined after the calendar-controller
      setTimeout(() => {
        this.onChange(this.date.format("YYYY-MM-DD"));
      });
    }
  }

  isSelectable(date) {
    return this.selectableDatesValue.includes(date);
  }

  hasPoint(date) {
    return this.markedDatesValue.includes(date);
  }

  onChange(date) {
    if (this.partnerIdValue) {
      window.dispatchEvent(
        new CustomEvent(`calendar_${this.partnerIdValue}:onchange`, {
          detail: { date },
        })
      );
    } else if (this.urlValue) {
      const url = new URL(
        window.location.protocol + "//" + window.location.host + this.urlValue
      );

      url.searchParams.set("date", date);

      Turbo.visit(url.toString(), { frame: "modal" });
    } else {
      window.dispatchEvent(
        new CustomEvent("calendar:onchange", { detail: { date } })
      );
    }
  }

  prevMonth() {
    --this.month;
    if (this.month === -1) {
      this.month = 11;
      --this.year;
    }
    this.generateCalendar();
  }

  nextMonth() {
    ++this.month;
    if (this.month === 12) {
      this.month = 0;
      ++this.year;
    }
    this.generateCalendar();
  }

  generateCalendar() {
    const month = this.month;
    const year = this.year;
    let days_of_month = [
      31,
      getFebDays(year),
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];

    this.daysTarget.innerHTML = "";

    let today = dayjs();

    this.monthTarget.innerHTML = month_names[month];
    this.yearTarget.innerHTML = year;

    let firstDay = dayjs().year(year).month(month).set("date", 1).locale("es");

    for (let i = 1; i <= days_of_month[month] + firstDay.weekday(); i++) {
      let day = document.createElement("div");
      let point = document.createElement("div");

      const indexDate = dayjs()
        .year(year)
        .month(month)
        .date(i - firstDay.weekday());

      if (i > firstDay.weekday()) {
        day.classList.add(
          "h-10",
          "w-10",
          "m-0.5",
          "flex",
          "items-center",
          "justify-center",
          "p-3",
          "relative"
        );

        if (today.isSame(indexDate, "day")) {
          day.classList.add("font-bold");
          day.classList.add("text-primary");
        }

        if (this.hasPoint(indexDate.format("YYYY-MM-DD"))) {
          point.classList.add(
            "h-1.5",
            "w-1.5",
            "rounded-full",
            "bg-primary",
            "absolute",
            "mt-1",
            "top-0"
          );
          day.innerHTML = `<div class="mt-2">${indexDate.date()}</div>`;
          day.appendChild(point);
        } else {
          day.innerHTML = indexDate.date();
        }

        if (this.isSelectable(indexDate.format("YYYY-MM-DD"))) {
          day.classList.add(
            "bg-gray-200",
            "rounded-full",
            "cursor-pointer",
            "hover:bg-primary",
            "hover:text-white",
            "border-2"
          );

          const selectDay = () => {
            this.daysTarget.childNodes.forEach((node) => {
              node.classList.remove(
                "text-white",
                "bg-primary",
                "border-primary",
                "hover:text-primary"
              );
              node.classList.add("bg-white");

              if (node.lastElementChild) {
                console.log(node.lastElementChild);
                node.lastElementChild.classList.remove("bg-white");
                node.lastElementChild.classList.add("bg-primary");
              }
            });

            day.classList.remove("bg-white");
            day.classList.add(
              "bg-gray-200",
              "rounded-full",
              "cursor-pointer",
              "hover:bg-primary",
              "hover:text-white",
              "border-2"
            );
            point.classList.remove("bg-primary");
            point.classList.add("bg-gray-200");
          };

          if (this.date.isSame(indexDate, "day")) {
            selectDay();
          }

          day.onclick = () => {
            selectDay();
            this.onChange(indexDate.format("YYYY-MM-DD"));
          };
        }
      }

      this.daysTarget.appendChild(day);
    }
  }
}
