import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "schedule",
    "selectedSchedules",
    "submitButton",
    "schedule",
  ];

  connect() {
    this.selectedSchedules = [];
    this.baseButtonText = "Agregar horarios a mi agenda"; // Texto base del botón
    this.updateButtonText(); // Inicializa el texto del botón al conectar
  }

  selectAll() {
    // do not select already selected schedules
    this.scheduleTargets.forEach((schedule) => {
      const checkbox = schedule.querySelector(".schedule-checkbox");
      const scheduleId = checkbox.value;
      if (!this.selectedSchedules.includes(scheduleId)) {
        schedule.click();
      }
    });
  }

  toggleSchedule(event) {
    const card = event.currentTarget;
    const checkbox = card.querySelector(".schedule-checkbox");
    const scheduleId = checkbox.value;
    const paragraph = card.querySelector("p");

    if (checkbox.checked) {
      // Des-seleccionar
      checkbox.checked = false;
      card.classList.remove("bg-primary", "text-white");
      card.classList.add("text-gray-700");
      paragraph.classList.remove("text-white");
      paragraph.classList.add("text-gray-700");
      this.selectedSchedules = this.selectedSchedules.filter(
        (id) => id !== scheduleId
      );
    } else {
      // Seleccionar
      checkbox.checked = true;
      card.classList.remove("text-gray-700");
      card.classList.add("bg-primary", "text-white");
      paragraph.classList.remove("text-gray-700");
      paragraph.classList.add("text-white");
      this.selectedSchedules.push(scheduleId);
    }

    this.selectedSchedulesTarget.value = JSON.stringify(this.selectedSchedules);
    this.updateButtonText();
  }

  updateButtonText() {
    const count = this.selectedSchedules.length;
    if (count < 1) {
      this.submitButtonTarget.classList.add("disabled");
      const newText = `Agregar horarios a mi agenda`; // Actualiza el texto con el número
      this.submitButtonTarget.value = newText; // Cambia el texto del botón
    } else if (count === 1) {
      this.submitButtonTarget.classList.remove("disabled");
      const newText = `Agregar ${count} horario a mi agenda`; // Actualiza el texto con el número
      this.submitButtonTarget.value = newText; // Cambia el texto
    } else {
      this.submitButtonTarget.classList.remove("disabled");
      const newText = `Agregar ${count} horarios a mi agenda`; // Actualiza el texto con el número
      this.submitButtonTarget.value = newText; // Cambia el texto del botón
    }
  }
}
