import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["timer", "critic"];

  connect() {
    this.endTime = new Date(this.data.get("endTime"));
    this.criticTime = new Date(this.data.get("criticTime"));
    this.color = this.data.get("color").split(" ");
    this.criticColor = this.data.get("criticColor").split(" ");
    this.endTimeLabel = this.data.get("endTimeLabel");
    const now = new Date();
    const timeRemaining = this.endTime - now;
    if (timeRemaining <= 0) {
      this.criticTarget.classList.add(...this.criticColor);
      this.timerTarget.textContent = this.endTimeLabel;
    } else {
      this.updateTimer();
      this.interval = setInterval(() => this.updateTimer(), 300000);
    }
  }

  disconnect() {
    clearInterval(this.interval);
  }

  updateTimer() {
    const now = new Date();
    const timeRemaining = this.endTime - now;
    const isCriticTime = this.criticTime - now <= 0;

    if (isCriticTime) {
      this.criticTarget.classList.remove(...this.color);
      this.criticTarget.classList.add(...this.criticColor);
    } else {
      this.criticTarget.classList.remove(...this.criticColor);
      this.criticTarget.classList.add(...this.color);
    }

    if (timeRemaining <= 0) {
      this.timerTarget.textContent = this.endTimeLabel;
      clearInterval(this.interval);
    } else {
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      if (days !== 0) {
        this.timerTarget.textContent = `${days} días y ${hours} horas`;
      } else if (hours) {
        this.timerTarget.textContent = `${hours} horas y ${minutes} minutos`;
      } else {
        this.timerTarget.textContent = `${minutes} minutos`;
      }
    }

    console.log(timeRemaining);
  }
}
