import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "submit"];

  connect() {
    this.isScrolledToBottom = false;
    this.updateButtonState();
  }

  scroll() {
    const container = this.containerTarget;
    const { scrollTop, scrollHeight, clientHeight } = container;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      this.isScrolledToBottom = true;
      this.updateButtonState();
    }
  }

  updateButtonState() {
    const button = this.submitTarget;
    button.disabled = !this.isScrolledToBottom;
  }

  submit(event) {
    event.preventDefault();
  }
}
